@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1, h2, h3, h4, h5, h6 {
    @apply font-serif;
  }
  
  body {
    @apply font-sans text-neutral;
  }
}

@layer components {
  .btn-primary {
    @apply bg-primary text-white px-8 py-3 rounded-md font-medium hover:bg-primary-dark transition-colors duration-300;
  }

  .btn-accent {
    @apply bg-accent text-neutral px-8 py-3 rounded-md font-medium hover:bg-accent-dark transition-colors duration-300;
  }

  .section-title {
    @apply text-3xl font-serif font-bold text-primary mb-6;
  }

  .section-subtitle {
    @apply text-xl text-neutral mb-8;
  }

  .hero {
    @apply relative min-h-[calc(100vh-64px)] bg-cover bg-center flex items-center justify-center mt-16;
  }

  .hero::before {
    @apply content-[''] absolute inset-0 bg-black bg-opacity-40;
  }

  .hero-content {
    @apply relative z-10 text-center text-white;
  }

  .hero-title {
    @apply text-5xl md:text-6xl font-serif font-bold mb-4;
  }

  .hero-subtitle {
    @apply text-xl md:text-2xl mb-8 max-w-2xl mx-auto;
  }
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom utility classes */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section {
  padding: 4rem 0;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
}

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-primary {
  background-color: #333;
  color: #fff;
}

.btn-primary:hover {
  background-color: #555;
}

.btn-outline {
  background-color: transparent;
  border: 2px solid #333;
  color: #333;
}

.btn-outline:hover {
  background-color: #333;
  color: #fff;
}