/* App-specific styles */

:root {
  --color-primary: #8B4513;
  --color-white: #FFFFFF;
  --color-accent-gold: #FFD700;
  --color-accent-gray: #2F4F4F;
}

/* Global Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Cormorant Garamond', serif;
}

body {
  font-family: 'Lato', sans-serif;
}

/* Hero section */
.hero {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  text-align: center;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  margin-top: 0;
  z-index: 1;
  padding-top: 64px;
  box-sizing: border-box;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/hero2.jpg');
  background-size: cover;
  background-position: center;
  filter: brightness(0.7);
  z-index: -1;
}

.hero-content {
  max-width: 800px;
  padding: 0 2rem;
  z-index: 1;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease forwards 0.5s;
}

.hero-title {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  letter-spacing: -0.02em;
  line-height: 1.2;
  color: var(--color-white);
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  color: var(--color-accent-gold);
}

/* Portfolio gallery */
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: 2px solid var(--color-primary);
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.gallery-item img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

/* Button styles */
.btn-primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: var(--color-accent-gray);
}

/* Text colors */
.text-primary {
  color: var(--color-primary);
}

.text-accent {
  color: var(--color-accent-gold);
}

/* Background colors */
.bg-primary {
  background-color: var(--color-primary);
}

.bg-accent {
  background-color: var(--color-accent-gold);
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Portfolio section */
.portfolio-section {
  padding: 4rem 0;
  background-color: #f8f8f8;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.portfolio-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.portfolio-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.portfolio-item img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.portfolio-item:hover img {
  transform: scale(1.05);
}

.portfolio-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: linear-gradient(to top, rgba(0,0,0,0.8), transparent);
  color: white;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.portfolio-item:hover .portfolio-overlay {
  opacity: 1;
  transform: translateY(0);
}

.portfolio-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.portfolio-category {
  font-size: 0.875rem;
  opacity: 0.8;
}